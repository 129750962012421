import { bindable, bindingMode, containerless } from 'aurelia-framework';

@containerless
export class SimpleSelect2 {

    placeholder = 'text.select-an-option';
    @bindable({ defaultBindingMode: bindingMode.twoWay }) items;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedOption;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) onSelectChange;
    @bindable pluginOptions;
    @bindable settings;
    @bindable classes;

    /**
     * Handles binding
     */
    bind() {
        this.fixData();

        this.placeholder = this.settings.placeholder ? this.settings.placeholder : this.placeholder;

        this.pluginOptions = {
            minimumResultsForSearch: this.settings.pluginOptions.minimumResultsForSearch || Infinity,
            allowClear:              this.settings.pluginOptions.allowClear,
            width:                   this.settings.pluginOptions.width || '100%',
        };

        if (!this.onSelectChange) this.onSelectChange = ($e) => {
        };
    }

    /**
     * Fixes data
     */
    fixData() {
        this.options = $.map(this.items, function (obj) {
            obj.text = obj.text || obj.name;

            return obj;
        });
    }

}
